import Vue from 'vue'
import Vuex from 'vuex'

import currentWriting from './modules/currentWriting'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    currentWriting
  }
})
