<template>
  <div class="app" :style="colours" >

    <router-view/>
  </div>
</template>

<script>
import Axios from 'axios'
// import Header from './components/Header.vue'
import pmfg from '../pmfg.txt'
export default {
  name: 'Hypotheticals',
  props: {
    msg: String
  },
  components: {
    // Header
  },
  computed: {
    colours () {
      const cols = {
        '--bg-color': this.$store.state.currentWriting.currentFunction.backgroundColour,
        '--txt-color': this.$store.state.currentWriting.currentFunction.textColour
      }
      return cols
    }
  },
  created () {
    Axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('atToken')
    Axios.defaults.headers.common['Content-Type'] = 'application/json'
    Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  },
  mounted () {
    this.$store.dispatch('currentWriting/loadFromImport', pmfg)
  }
}
</script>

<style>
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
.app {
  font-family: Raleway, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--txt-color);
  background-color: var(--bg-color);
  min-height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #552000;
}

#nav a.router-link-exact-active {
  color: #c050c0;
}

html, body {
  margin: 0;
  height: 100%;
  color: var(--txt-color);
  background-color: var(--bg-color);
  transition: background-color 1s ease;
  scroll-behavior: smooth;
}
</style>
