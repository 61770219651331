import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMediaEmbed from 'vue-media-embed'
// import { Editor, EditorContent } from 'tiptap'

Vue.config.productionTip = false

// Vue.component('editor', { /* ... */ })
// Vue.component('editor-content', { /* ... */ })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueMediaEmbed, { store })
// Vue.use(Editor, { store })
// Vue.use(EditorContent, { store })
