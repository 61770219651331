import Vue from 'vue'

const state = {
  projects: [],
  currentProject: {
    title: 'Unnamed Project',
    rootFunction: '',
    bookmarks: [],
    functions: [],
    lookSettings: {
      defaultBackgroundColour: '#000033',
      defaultTextColour: '#FFFFFF',
      predecessorButton: '',
      successorButton: '',
      childButton: '',
      parentButton: '',
      rootButton: '',
      bookmarkButton: '',
      primaryHeader: '',
      secondaryHeader: '',
      authorLink: '',
      supportLink: '',
      supportImage: ''
    }
  },
  currentFunction: {
    title: 'Insert Title',
    type: 'prose',
    renderWith: 'Draftboard',
    parent: 'root',
    predecessor: '',
    successor: '',
    project: '',
    aspects: [],
    aspectMedia: [],
    templates: [],
    childList: [],
    backgroundColour: '#000033',
    textColour: '#FFFFFF',
    headerDisplay: 0
  },
  utilities: {
    changeCounter: '',
    newTitle: 'New Function',
    newTitleUnique: false,
    currentTitleUnique: false,
    testTitle: '',
    testTitleUnique: false,
    updatedFunctions: [],
    loaded: false,
    rawLoadedText: ''
  }
}
const mutations = {
  newAspect (state, medium) {
    state.currentFunction.aspects.push(undefined)
    state.currentFunction.aspectMedia.push(medium)
  },
  updateAspect (state, setAspect) {
    // state.currentFunction.aspects[setAspect.id] = setAspect.content
    Vue.set(state.currentFunction.aspects, setAspect.id, setAspect.content)
  },
  subtractAspect (state, id) {
    state.currentFunction.aspects.splice(id, 1)
    state.currentFunction.aspectMedia.splice(id, 1)
  },
  swapAspectUp (state, id) {
    const oldID = id
    const newID = id - 1

    const moverContents = state.currentFunction.aspects[oldID]
    const movedContents = state.currentFunction.aspects[newID]
    // state.currentFunction.aspects[oldID] = state.currentFunction.aspects[newID]
    // Vue.set(state.currentFunction.aspects, oldID, state.currentFunction.aspects[newID])
    state.currentFunction.aspects.splice(oldID, 1, movedContents)
    // state.currentFunction.aspects[newID] = tmp
    // Vue.set(state.currentFunction.aspects, newID, tmp)
    state.currentFunction.aspects.splice(newID, 1, moverContents)
    const moverContents2 = state.currentFunction.aspectMedia[oldID]
    const movedContents2 = state.currentFunction.aspectMedia[newID]
    // state.currentFunction.aspectMedia[oldID] = state.currentFunction.aspectMedia[newID]
    // Vue.set(state.currentFunction.aspectMedia, oldID, state.currentFunction.aspectMedia[newID])
    state.currentFunction.aspectMedia.splice(oldID, 1, movedContents2)
    // state.currentFunction.aspectMedia[newID] = tmp2
    // Vue.set(state.currentFunction.aspectMedia, newID, tmp2)
    state.currentFunction.aspectMedia.splice(newID, 1, moverContents2)
  },
  swapAspectDown (state, id) {
    const oldID = id
    const newID = id + 1

    const moverContents = state.currentFunction.aspects[oldID]
    const movedContents = state.currentFunction.aspects[newID]
    // state.currentFunction.aspects[oldID] = state.currentFunction.aspects[newID]
    // Vue.set(state.currentFunction.aspects, oldID, state.currentFunction.aspects[newID])
    state.currentFunction.aspects.splice(oldID, 1, movedContents)
    // state.currentFunction.aspects[newID] = tmp
    // Vue.set(state.currentFunction.aspects, newID, tmp)
    state.currentFunction.aspects.splice(newID, 1, moverContents)
    const moverContents2 = state.currentFunction.aspectMedia[oldID]
    const movedContents2 = state.currentFunction.aspectMedia[newID]
    // state.currentFunction.aspectMedia[oldID] = state.currentFunction.aspectMedia[newID]
    // Vue.set(state.currentFunction.aspectMedia, oldID, state.currentFunction.aspectMedia[newID])
    state.currentFunction.aspectMedia.splice(oldID, 1, movedContents2)
    // state.currentFunction.aspectMedia[newID] = tmp2
    // Vue.set(state.currentFunction.aspectMedia, newID, tmp2)
    state.currentFunction.aspectMedia.splice(newID, 1, moverContents2)
  },

  newTemplate (state, template) {
    state.currentFunction.templates.push(template)
  },
  deleteTemplate (state, id) {
    state.currentFunction.templates.splice(id, 1)
  },
  changeType (state, type) {
    state.currentFunction.type = type
  },
  changeRenderer (state, renderWith) {
    state.currentFunction.renderWith = renderWith
  },
  changeBackgroundColour (state, newBackgroundColour) {
    state.currentFunction.backgroundColour = newBackgroundColour
  },
  changeDefaultBackgroundColour (state, newBackgroundColour) {
    state.currentProject.lookSettings.defaultBackgroundColour = newBackgroundColour
  },
  changeTextColour (state, newTextColour) {
    state.currentFunction.textColour = newTextColour
  },
  changeDefaultTextColour (state, newTextColour) {
    state.currentProject.lookSettings.defaultTextColour = newTextColour
  },
  changePredecessorButton (state, newPredecessorButton) {
    state.currentProject.lookSettings.predecessorButton = newPredecessorButton
  },
  changeSuccessorButton (state, newSuccessorButton) {
    state.currentProject.lookSettings.successorButton = newSuccessorButton
  },
  changeChildButton (state, newChildButton) {
    state.currentProject.lookSettings.childButton = newChildButton
  },
  changeParentButton (state, newParentButton) {
    state.currentProject.lookSettings.parentButton = newParentButton
  },
  changeRootButton (state, newRootButton) {
    state.currentProject.lookSettings.rootButton = newRootButton
  },
  changeBookmarkButton (state, newBookmarkButton) {
    state.currentProject.lookSettings.bookmarkButton = newBookmarkButton
  },
  changePrimaryHeader (state, newPrimaryHeader) {
    state.currentProject.lookSettings.primaryHeader = newPrimaryHeader
  },
  changeSecondaryHeader (state, newSecondaryHeader) {
    state.currentProject.lookSettings.secondaryHeader = newSecondaryHeader
  },
  changeHeaderDisplay (state, newHeaderDisplay) {
    state.currentFunction.headerDisplay = newHeaderDisplay
  },
  changeAuthorLink (state, newAuthorLink) {
    state.currentProject.lookSettings.authorLink = newAuthorLink
  },
  changeSupportLink (state, newSupportLink) {
    state.currentProject.lookSettings.supportLink = newSupportLink
  },
  changeSupportImage (state, newSupportImage) {
    state.currentProject.lookSettings.supportImage = newSupportImage
  },
  testTitle (state, testTitle) {
    // this will test any title given to it.
    state.utilities.testTitle = testTitle
    const titleCollisions = state.currentProject.functions.find(el => el.title === testTitle)
    if (titleCollisions === undefined) {
      state.utilities.testTitleUnique = true
    } else {
      state.utilities.testTitleUnique = false
    }
  },
  newTitle (state, title) {
    state.utilities.newTitle = title
    state.utilities.newTitleUnique = state.utilities.testTitleUnique
  },
  updateTitle (state, title) {
    if (state.utilities.testTitleUnique === true) {
      state.utilities.newTitle = title
      state.utilities.currentTitleUnique = state.utilities.testTitleUnique

      // for each element in state.currentproject.functions
      state.currentProject.functions.forEach(function (el, index) {
        // if its value for successor is identical to state.currentFunction.title
        if (el.successor === state.currentFunction.title && state.currentFunction.title) {
          // set that successor value to state.utilities.newTitle.
          el.successor = state.utilities.newTitle
        }
        // if the predecessor value for element is identical to state.currentFunction.title
        if (el.predecessor === state.currentFunction.title && state.currentFunction.title) {
          // set that predecessor value to state.utilities.newTitle
          el.predecessor = state.utilities.newTitle
        }
        // if the parent value for element is identical to state.currentFunction.title
        if (el.parent === state.currentFunction.title && state.currentFunction.title) {
          // set that parent value to state.utilities.newTitle
          el.parent = state.utilities.newTitle
        }
      })
      if (state.currentProject.rootFunction === state.currentFunction.title && state.currentFunction.title) {
        state.currentProject.rootFunction = state.utilities.newTitle
      }
      if (state.currentProject.bookmarks.includes(state.currentFunction.title)) {
        const bookmark = state.currentProject.bookmarks.indexOf(state.utilities.newTitle)
        state.currentProject.bookmarks.splice(bookmark, 1)
        state.currentProject.bookmarks.push(state.utilities.newTitle)
      }
      state.currentFunction.title = state.utilities.newTitle
      state.utilities.newTitle = 'Function' + state.currentProject.functions.length
    }
  },
  updateProjectTitle (state, newProjectTitle) {
    state.currentProject.title = newProjectTitle
  },
  pushNewFunction (state, newFunction) {
    state.currentProject.functions.push(newFunction)
    state.utilities.newTitle = 'Function' + state.currentProject.functions.length
  },
  updateFunctionInProjectByID (state, functionToSaveTo) {
    state.currentProject.functions[functionToSaveTo] = state.currentFunction
  },
  loadFunctionByID (state, functionID) {
    state.currentFunction = state.currentProject.functions[functionID]
    state.utilities.loaded = false
  },
  loadFunctionByArray (state, functionArray) {
    state.currentFunction = functionArray
  },
  loadFunctionByName (state, name) {

  },
  loadProjectByID (state, projectID) {
    state.currentProject = state.projects[projectID]
  },
  loadProjectFromJSON (state, jsonData) {
    state.currentProject = jsonData
  },
  writeChildList (state, newChildList) {
    state.currentFunction.childList = newChildList
  },
  updateOldAdjacent (state, payload) {
    if (payload.position === 'successor') {
      const functionToUpdate = state.currentProject.functions[payload.index]
      functionToUpdate.successor = payload.newTitle
      state.currentProject.functions[payload.index] = functionToUpdate
    } else if (payload.position === 'predecessor') {
      const functionToUpdate = state.currentProject.functions[payload.index]
      functionToUpdate.predecessor = payload.newTitle
      state.currentProject.functions[payload.index] = functionToUpdate
    }
  },
  prepareCurrentFunctionForChaining (state, payload) {
    if (payload.direction === 'successor') {
      state.currentFunction.successor = payload.title
    }
    if (payload.direction === 'predecessor') {
      state.currentFunction.predecessor = payload.title
    }
  },
  updateUpdatedFunctions (state) {
    if (!state.utilities.updatedFunctions.includes(state.currentFunction.title)) {
      state.utilities.updatedFunctions.push(state.currentFunction.title)
    }
    ++state.utilities.changeCounter
  },
  wipeUpdatedFunctions (state) {
    state.utilities.updatedFunctions = []
    state.utilities.changeCounter = 0
  },
  saveToProjectsArray (state) {
    if (state.projects.find(el => el.title === state.currentProject.title) === undefined) {
      state.projects.push(state.currentProject)
    } else {
      const currentProjectIndex = state.projects.indexOf(el => el.title === state.currentProject.title)
      state.projects[currentProjectIndex] = state.currentProject
    }
  },
  makeRoot (state) {
    state.currentProject.rootFunction = state.currentFunction.title
  },
  wipeCurrentProject (state) {
    const newProject = {
      title: 'Unnamed Project' + (state.projects.length + 1),
      rootFunction: 'Insert Title',
      functions: []
    }
    state.currentProject = newProject
  },
  makeBookmark (state, title) {
    if (!state.currentProject.bookmarks.includes(title)) {
      state.currentProject.bookmarks.push(title)
    }
  },
  removeBookmark (state, title) {
    if (state.currentProject.bookmarks.includes(title)) {
      const location = state.currentProject.bookmarks.indexOf(title)
      state.currentProject.bookmarks.splice(location, 1)
    }
  },
  imgLoaded () {
    state.utilities.loaded = true
  }
}
const actions = {
  save ({ commit, dispatch }, currentFunction, currentProject) {
    const functionToSaveTo = state.currentProject.functions.findIndex(el => el.title === state.currentFunction.title)
    if (functionToSaveTo === -1) {
      commit('pushNewFunction', state.currentFunction)
    } else {
      commit('updateFunctionInProjectByID', functionToSaveTo)
    }
    commit('updateUpdatedFunctions')
    if (state.utilities.updatedFunctions.length >= 2 || state.utilities.changeCounter >= 50) {
      state.utilities.updatedFunctions.forEach(function (el) {
        const functionsToSend = []
        functionsToSend.push(state.currentProject.functions[el])
      })
      console.log('<<This message appears when the software would save your work to the cloud. However, in this demo version this feature has been disabled.>>')
    }
    commit('saveToProjectsArray')
  },
  goToSuccessor ({ commit, dispatch }, currentFunction, currentProject) {
    dispatch('save')
    const successorID = state.currentProject.functions.findIndex(el => el.title === state.currentFunction.successor)
    commit('loadFunctionByID', successorID)
    dispatch('populateChildList')
  },
  goToPredecessor ({ commit, dispatch }, currentFunction, currentProject) {
    dispatch('save')
    const predecessorID = state.currentProject.functions.findIndex(el => el.title === state.currentFunction.predecessor)
    commit('loadFunctionByID', predecessorID)
    dispatch('populateChildList')
  },
  goToParent ({ commit, dispatch }, currentFunction, currentProject) {
    dispatch('save')
    const parentID = state.currentProject.functions.findIndex(el => el.title === state.currentFunction.parent)
    commit('loadFunctionByID', parentID)
    dispatch('populateChildList')
  },
  goToChild ({ commit, dispatch }, child, currentFunction, currentProject) {
    dispatch('save')
    const childID = state.currentProject.functions.findIndex(el => el.title === child)
    commit('loadFunctionByID', childID)
    dispatch('populateChildList')
  },
  goToFunction ({ commit, dispatch }, functionName, currentFunction, currentProject) {
    dispatch('save')
    const functionID = state.currentProject.functions.findIndex(el => el.title === functionName)
    commit('loadFunctionByID', functionID)
    dispatch('populateChildList')
  },
  goToRoot ({ commit, dispatch }, currentFunction, currentProject) {
    dispatch('save')
    if (state.currentProject.rootFunction) {
      const rootID = state.currentProject.functions.findIndex(el => el.title === state.currentProject.rootFunction)
      commit('loadFunctionByID', rootID)
      dispatch('populateChildList')
    }
  },
  goToBookmark ({ commit, dispatch }, bookmark, currentFunction, currentProject) {
    dispatch('save')
    if (state.currentProject.bookmarks) {
      const bookmarkID = state.currentProject.functions.findIndex(el => el.title === bookmark)
      commit('loadFunctionByID', bookmarkID)
      dispatch('populateChildList')
    }
  },
  goToProject ({ commit, dispatch }, projectName, currentFunction, currentProject) {
    dispatch('save')
    const projectID = state.projects.findIndex(el => el.title === projectName)
    commit('loadProjectByID', projectID)
    dispatch('goToRoot')
  },
  populateChildList ({ commit, dispatch }, currentFunction, currentProject) {
    const newChildList = []
    state.currentProject.functions.forEach(function (el) {
      if (el.parent === state.currentFunction.title) {
        newChildList.push(el.title)
      }
    })
    commit('writeChildList', newChildList)
  },
  createFunction ({ commit, dispatch }, payload, currentFunction, currentProject) {
    commit('prepareCurrentFunctionForChaining', payload)
    dispatch('save')
    let newFunction = {}
    if (payload.direction === 'successor') {
      newFunction = {
        title: payload.title,
        type: 'prose',
        renderWith: 'Draftboard',
        parent: state.currentFunction.parent,
        predecessor: state.currentFunction.title,
        successor: '',
        project: state.currentProject.title,
        aspects: [],
        aspectMedia: [],
        // templates: [],
        childList: [],
        backgroundColour: state.currentProject.lookSettings.defaultBackgroundColour,
        textColour: state.currentProject.lookSettings.defaultTextColour
      }
      if (state.currentFunction.successor) {
        const oldSuccessor = {
          index: state.currentProject.functions.findIndex(el => el.successor === state.currentFunction.successor),
          position: 'successor',
          newTitle: payload.title
        }
        commit('updateOldAdjacent', oldSuccessor)
      }
    } else if (payload.direction === 'predecessor') {
      newFunction = {
        title: payload.title,
        type: 'prose',
        renderWith: 'Draftboard',
        parent: state.currentFunction.parent,
        predecessor: state.currentFunction.title,
        successor: '',
        project: state.currentProject.title,
        aspects: [],
        aspectMedia: [],
        // templates: [],
        childList: [],
        backgroundColour: state.currentProject.lookSettings.defaultBackgroundColour,
        textColour: state.currentProject.lookSettings.defaultTextColour
      }
      if (state.currentFunction.predecessor) {
        const oldPredecessor = {
          index: state.currentProject.functions.findIndex(el => el.predecessor === state.currentFunction.predecessor),
          position: 'predecessor',
          newTitle: payload.title
        }
        commit('updateOldAdjacent', oldPredecessor)
      }
    } else if (payload.direction === 'child') {
      newFunction = {
        title: payload.title,
        type: 'prose',
        renderWith: 'Draftboard',
        parent: state.currentFunction.title,
        predecessor: '',
        successor: '',
        project: state.currentProject.title,
        aspects: [],
        aspectMedia: [],
        // templates: [],
        childList: [],
        backgroundColour: state.currentProject.lookSettings.defaultBackgroundColour,
        textColour: state.currentProject.lookSettings.defaultTextColour
      }
      dispatch('populateChildList')
    } else if (payload.direction === 'new') {
      newFunction = {
        title: 'Insert Title',
        type: 'prose',
        renderWith: 'Draftboard',
        parent: 'root',
        predecessor: '',
        successor: '',
        project: 'Untitled Project' + (state.projects.length),
        aspects: [],
        aspectMedia: [],
        // templates: [],
        childList: [],
        backgroundColour: state.currentProject.lookSettings.defaultBackgroundColour,
        textColour: state.currentProject.lookSettings.defaultTextColour
      }
      dispatch('populateChildList')
    }
    commit('pushNewFunction', newFunction)
    commit('loadFunctionByArray', newFunction)
  },
  newProject ({ commit, dispatch }, currentFunction, currentProject) {
    dispatch('save')
    commit('wipeCurrentProject')
    const payload = {
      direction: 'new'
    }
    dispatch('newFunction', payload)
    commit('goToRoot')
  },
  loadUserProjects ({ commit, dispatch }, currentFunction, currentProject) {

  },
  async exportProject ({ commit, dispatch }, currentFunction, currentProject) {
    await dispatch('save')
    const projectFile = JSON.stringify(state.currentProject)
    function download (content, fileName, contentType) {
      const a = document.createElement('a')
      const file = new Blob([content], { type: contentType })
      a.href = URL.createObjectURL(file)
      a.download = fileName
      a.click()
      // For Firefox it is necessary to delay revoking the ObjectURL.
      setTimeout(() => {
        window.URL.revokeObjectURL(a.href)
      }, 250)
    }
    const nameOfFile = state.currentProject.title + '.txt'
    download(projectFile, nameOfFile, 'text/plain')
  },
  async loadFromFile ({ commit, dispatch }, payload, currentFunction, currentProject) {
    const file = payload.target.files[0]
    const reader = new FileReader()
    let rawText = ''
    reader.readAsText(file)
    reader.onload = function () {
      rawText = reader.result
      const rawJSON = JSON.parse(rawText)
      commit('loadProjectFromJSON', rawJSON)
      if (state.currentProject.rootFunction) {
        dispatch('goToRoot')
      } else {
        const goodIndex = state.currentProject.functions.findIndex(function (testFunction) {
          return !!testFunction.successor && testFunction.parent === 'root'
        })
        commit('loadFunctionByID', goodIndex)
      }
    }
  },
  async loadFromImport ({ commit, dispatch }, payload, currentFunction, currentProject) {
    const startingState = JSON.parse(payload)
    commit('loadProjectFromJSON', startingState)
    if (state.currentProject.rootFunction) {
      dispatch('goToRoot')
    } else {
      const goodIndex = state.currentProject.functions.findIndex(function (testFunction) {
        return !!testFunction.successor && testFunction.parent === 'root'
      })
      commit('loadFunctionByID', goodIndex)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
